import type { NextPage } from 'next'
import { Home } from '@/client/modules/Home'
import { HomeProps } from '@/client/modules/Home/types'
import { getCMSContent } from '@/client/utils'

const HomePage: NextPage<HomeProps> = (data) => <Home {...data} />

export async function getStaticProps({ defaultLocale, locale }) {
  const data = await getCMSContent(defaultLocale, locale, 'HOME')

  if (!data?.content) return { notFound: true }

  return {
    revalidate: 10,
    props: { ...data }
  }
}

export default HomePage
