import { FC } from 'react'
import { Row, Col, Text, List, ListItem } from '@/client/components'
import * as S from './styles'
import { AdvantagesProps, RatingProps } from '../types'

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  fade: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
}

const Customer: FC<RatingProps> = ({ rating, name, position, company, image, testimonial }) => (
  <S.Customer>
    <S.Rating stars={5} filled={Number(rating)} />
    <Text color='weak' size='sm'>
      {testimonial}
    </Text>

    <S.CustomerInfos>
      <S.CustomerInfosLeft>
        <S.AvatarFirst image={image.url} alt={image.alt} hasBorder />
      </S.CustomerInfosLeft>
      <div>
        <Text size='sm' weight='bold' margin='0'>
          {name}
        </Text>
        <Text size='xs' color='weak' margin='0'>
          {position} - {company}
        </Text>
      </div>
    </S.CustomerInfos>
  </S.Customer>
)

const Advantages: FC<AdvantagesProps> = ({ advantages: { title, ratings, list } }) => (
  <S.Advantages>
    <Row>
      <Col xs={12}>
        <Text size='xlg' family='heading' weight='bold' margin='0 0 2rem'>
          {title}
        </Text>
      </Col>
    </Row>
    <Row>
      <Col
        xs={12}
        lg={4}
        orders={[
          {
            mq: 'xs',
            order: 2
          },
          {
            mq: 'lg',
            order: 1
          }
        ]}
      >
        {/*  @ts-ignore */}
        <etrusted-widget data-etrusted-widget-id='wdg-694ae81b-501b-40c6-ae55-c53553c046ba'></etrusted-widget>
      </Col>
      <Col
        xs={12}
        lg={7}
        lgOffset={1}
        orders={[
          {
            mq: 'xs',
            order: 1
          },
          {
            mq: 'lg',
            order: 2
          }
        ]}
      >
        <List kind='gold' double>
          {list.map(({ item }) => (
            <ListItem key={item}>{item}</ListItem>
          ))}
        </List>
      </Col>
    </Row>
  </S.Advantages>
)

export default Advantages
