import { FC, useState, useEffect } from 'react'
import { Text, Image, Button, Icon, Link } from '@/client/components'
import * as S from './styles'
import { HeroProps } from '../types'
import { SliderArrow } from '@/client/submodules/Slider/SliderArrow'
import { useWindowSize } from '@/client/hooks/windowSize'

export const Hero: FC<HeroProps> = ({ hero }) => {
  const [hasDots, setDots] = useState(true)
  const settings = {
    dots: hasDots,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    prevArrow: <SliderArrow position='left' slidesToShow={1} shape='rectangle' isInfinite />,
    nextArrow: <SliderArrow position='right' slidesToShow={1} shape='rectangle' isInfinite />,
    afterChange: (currentSlide: number) => {
      currentSlide === 3 ? setDots(false) : setDots(true)
    }
  }

  const { breakpoint } = useWindowSize()
  const [CxPosition, setCxPosition] = useState({ x: 0 }) // Get Mouse x,y position on mouseMove
  const [CxLastPosition, setCxLastPosition] = useState({ x: 0 }) // Get last position on mouseUp
  const [isDragging, setIsDragging] = useState(false) // Drag status

  /**
   * Handles on mouse move.
   *
   * @param {string} e - default param.
   * @returns null
   */
  const handleMouseMove = (e) => {
    const sliderElement = document.getElementById('hero')
    const sliderRect = sliderElement?.getBoundingClientRect()
    if (!sliderRect) return
    const widthSize = window.innerWidth / 16
    let leftLimit = widthSize > 90 ? 300 : 100 // < 90 desktop : > 90 wide-desktop
    const rightLimit = sliderRect.right - leftLimit
    const mouseX = e.clientX
    const newLeft = Math.max(leftLimit, Math.min(mouseX, rightLimit)) // Keep the trigger-bar within the limits
    setCxPosition({ x: newLeft })
  }

  const bodyMouseUp = (e) => {
    setIsDragging(false)
    const sliderElement = document.getElementById('hero')
    if (!sliderElement) return
    const getFirstLeft = sliderElement.offsetWidth / 2
    setCxLastPosition({ x: getFirstLeft })
  }

  /**
   * Handles on mouse up.
   *
   * @param {string} e - default param.
   * @returns null
   */
  const handleMouseUp = (e) => {
    setIsDragging(false)
    setCxLastPosition({ x: CxPosition.x })
  }

  /**
   * Handles on mouse down.
   *
   * @param {string} e - default param.
   * @returns null
   */

  const handleMouseDown = (e) => {
    setIsDragging(true)
    const sliderElement = document.getElementById('hero')
    const sliderRect = sliderElement?.getBoundingClientRect()
    if (!sliderRect) return
    let leftLimit = breakpoint === 'wide-desktop' ? 300 : 100
    const rightLimit = sliderRect.right - leftLimit
    const mouseX = e.clientX
    const newLeft = Math.max(leftLimit, Math.min(mouseX, rightLimit)) // Keep the trigger-bar within the limits
    setCxPosition({ x: newLeft })
  }

  useEffect(() => {
    const sliderElement = document.getElementById('hero')
    if (!sliderElement) return

    sliderElement.addEventListener('mousemove', handleMouseMove)
    sliderElement.addEventListener('mouseup', bodyMouseUp)
    // Get initial value.
    const getFirstLeft = sliderElement.offsetWidth / 2
    setCxLastPosition({ x: getFirstLeft })

    return () => {
      sliderElement.removeEventListener('mousemove', handleMouseMove)
      sliderElement.removeEventListener('mouseup', bodyMouseUp)
    }
  }, [])

  return (
    <S.Hero id='hero'>
      <S.Slide {...settings} slider={hasDots}>
        {hero.accordion_container.map(
          ({
            subtitle,
            main_heading,
            long_text,
            button_text,
            main_button_link,
            image,
            first_side_button,
            first_side_button_text,
            first_side_button_link,
            second_side_button,
            second_side_button_text,
            second_side_button_link,
            third_side_button,
            third_side_button_link
          }) => (
            <S.SliderContentWrapper key={image.id}>
              <S.FirstBlock>
                <Text size='md' color='inverse' weight='bold' className='subtitle' margin='0 0 2rem'>
                  {subtitle}
                </Text>
                <Text size='xxlg' weight='black' family='heading' lineHeight='1.2'>
                  {main_heading}
                </Text>
                <Text size='md' lineHeight='1.2'>
                  {long_text}
                </Text>
                <Link href={main_button_link}>
                  <Button kind='warning' id='header_main_button' style={{ borderRadius: '2rem', marginTop: '2rem' }}>
                    {button_text}
                    <Icon name='chevron-right' className='mainButtonIcon' />
                  </Button>
                </Link>
              </S.FirstBlock>
              <S.ImageBlock>
                <Image src={image.url} alt='header-image-1' width='400' height='300' className='img' priority />
              </S.ImageBlock>

              <S.ThirdBlock>
                <S.ServiceDetails>
                  <S.ImageAligner>
                    <Image src={`/img/sample-book.png`} alt='sample-book' width='65' height='70' />
                  </S.ImageAligner>
                  <Link href={first_side_button_link} className='slide-link'>
                    <S.TextAligner>
                      <Text size='sm' weight='bold' margin='0.5rem 0 0' lineHeight='1.3'>
                        {first_side_button}
                      </Text>
                      <Text size='xs' margin='0 0 0.5rem' italic color='weak' lineHeight='1.3'>
                        {first_side_button_text}
                      </Text>
                    </S.TextAligner>
                    <>
                      <Icon name='arrow-right' size='s6' className='sideIcon' />
                    </>
                  </Link>
                </S.ServiceDetails>

                <S.ServiceDetails>
                  <S.ImageAligner>
                    <Image src={`/img/express-service.png`} alt='express-service' width='60' height='70' />
                  </S.ImageAligner>
                  <Link href={second_side_button_link} className='slide-link'>
                    <S.TextAligner>
                      <Text size='sm' weight='bold' margin='0.5rem 0 0' lineHeight='1.3'>
                        {second_side_button}
                      </Text>
                      <Text size='xs' margin='0 0 0.5rem' italic color='weak' lineHeight='1.3'>
                        {second_side_button_text}
                      </Text>
                    </S.TextAligner>
                    <>
                      <Icon name='arrow-right' size='s6' className='icon' />
                    </>
                  </Link>
                </S.ServiceDetails>

                <S.ServiceDetails endButton={true}>
                  <Link href={third_side_button_link} className='slide-link'>
                    <S.TextAligner>
                      <Text size='sm' weight='bold' margin='1rem 0'>
                        {third_side_button}
                      </Text>
                    </S.TextAligner>
                    <>
                      <Icon name='arrow-right' size='s6' className='icon' />
                    </>
                  </Link>
                </S.ServiceDetails>
              </S.ThirdBlock>
            </S.SliderContentWrapper>
          )
        )}
        <div id='slider-component' onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
          <S.HeroWrapper id='heroWrapper'>
            <S.BgWrap id='bg-video-wrap' style={{ background: `url(${hero.background_image['url']})` }}>
              <div
                id='trigger-bar'
                style={{
                  left: isDragging ? CxPosition.x + 'px' : CxLastPosition.x + 'px',
                  cursor: isDragging ? 'grabbing' : 'grab'
                }}
              >
                <div className={isDragging ? 'clicked trigger-svg-wrapper' : 'trigger-svg-wrapper'}>
                  <svg
                    width='48'
                    height='48'
                    viewBox='0 0 48 48'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    style={{ opacity: 1 }}
                  >
                    <circle cx='24' cy='24' r='24' fill='black' fillOpacity='0.4'></circle>
                    <path d='M42 24L34.5 15.3397L34.5 32.6603L42 24Z' fill='#FFFFFF'></path>
                    <rect x='20' y='6' width='8' height='36' rx='1' fill='#FFFFFF'></rect>
                    <path d='M6 24L13.5 15.3397L13.5 32.6603L6 24Z' fill='#FFFFFF'></path>
                  </svg>
                </div>
              </div>
              <div
                className='foreground-img'
                style={{ left: `${isDragging ? CxPosition.x + 'px' : CxLastPosition.x + 'px'}` }}
              >
                <div
                  className='foreground-img-core'
                  style={{
                    background: `url(${hero.main_image['url']})`,
                    left: `${isDragging ? CxPosition.x * -1 + 'px' : CxLastPosition.x * -1 + 'px'}`
                  }}
                ></div>
              </div>
            </S.BgWrap>
          </S.HeroWrapper>
        </div>
      </S.Slide>
    </S.Hero>
  )
}

export default Hero
