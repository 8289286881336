import styled, { css } from 'styled-components'
import { theme } from '@/client/components'
import breakpoint from '@/client/components/theme/breakpoint'
const { color, space, borders } = theme

const getShapeStyle = (shape: string) => {
  if (shape === 'circle') {
    return css`
      border: ${borders.sizes.md} solid ${color.primary.darker};
      border-radius: ${borders.radius.circle};
      background-color: transparent;
      color: ${color.primary.darker};
      transition: 0.3s ease;
    `
  }
}

export const ArrowButton = styled.button<{ shape: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${space.xl2};
  height: ${space.xl2};

  &:before {
    display: none;
  }

  &:disabled {
    border-color: ${color.grayscale.gray};
    color: ${color.grayscale.gray};
  }

  svg {
    fill: currentColor;
    width: ${space.md};
    height: ${space.md};
  }

  &:hover,
  &:focus,
  &:active {
    transition: 0.3s ease;
    opacity: 0.6;
    color: ${color.primary.darker};
  }

  ${({ shape }) => getShapeStyle(shape)};
`
