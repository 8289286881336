import { FC } from 'react'
import { Row, Col, Text, Image } from '@/client/components'
import * as S from './styles'
import { ReferencesProps } from '../types'

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    },
    {
      breakpoint: 99999,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5
      }
    }
  ]
}

const References: FC<ReferencesProps> = ({ references: { title, list } }) => (
  <S.References>
    <Row>
      <Col xs={12}>
        <Text size='xlg' family='heading' weight='bold' margin='0 0 2rem'>
          {title}
        </Text>

        <S.Slide {...settings}>
          {list.map(({ image }) => (
            <S.Reference key={image?.alt}>
              <S.ReferenceImage>
                <Image src={image?.url} alt={image?.alt} width={image?.width} height={image?.height} />
              </S.ReferenceImage>
            </S.Reference>
          ))}
        </S.Slide>
      </Col>
    </Row>
  </S.References>
)

export default References
