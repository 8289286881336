import { FC } from 'react'
import { Row, Col, Text, Icon, Image } from '@/client/components'
import { useRouterPush } from '@/client/hooks'
import * as S from './styles'
import { AboutProps } from '../types'

const About: FC<AboutProps> = ({ about: { title, description, image, button } }) => {
  const { routerPush } = useRouterPush()

  return (
    <S.About>
      <Row>
        <Col xs={12} lg={7} xl={9}>
          <Text size='xlg' family='heading' weight='bold'>
            {title}
          </Text>
          {description.map(({ paragraph }) => (
            <Text key={paragraph} color='weak'>
              {paragraph}
            </Text>
          ))}
        </Col>

        <Col xs={12} lg={5} xl={3}>
          <S.BorderImage>
            <Image src={image.url} alt={image.alt} width={image.width} height={image.height} />
          </S.BorderImage>
          <S.Button onClick={() => routerPush(button.link)}>
            <Icon name='arrow-right' />
            {button.label}
          </S.Button>
        </Col>
      </Row>
    </S.About>
  )
}

export default About
