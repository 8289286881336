import { FC, useCallback, useRef, useState, MutableRefObject, useMemo } from 'react'
import Image from 'next/image'
import { default as SliderProps } from 'react-slick'
import Slider from 'react-slick'
import { Row, Col, Text, HorizontalScroll } from '@/client/components'
import { useRouterPush, useWindowSize } from '@/client/hooks'
import { SliderArrow } from '@/client/submodules/Slider'
import * as S from './styles'
import { LabelsProps } from '../types'

const Labels: FC<LabelsProps> = ({ labels: { title, subtitle, list } }) => {
  const { routerPush } = useRouterPush()
  const sliderRef = useRef() as MutableRefObject<SliderProps>
  const [index, setIndex] = useState<number>(0)
  const windowSize = useWindowSize()
  const isLgBreakpoint = windowSize?.width > 768

  const handlePrev = useCallback(() => {
    sliderRef.current.slickPrev()
  }, [sliderRef])

  const handleNext = useCallback(() => {
    sliderRef.current.slickNext()
  }, [sliderRef])

  const beforeChange = useCallback((prev, next) => {
    setIndex(next)
  }, [])

  const settings = {
    infinite: false,
    speed: 500,
    beforeChange,
    slidesToShow: 3,
    slidesToScroll: 3
  }

  const listLabels = useMemo(
    () =>
      list.map(({ icon, title, color, link }) => (
        <S.LabelButton
          key={title}
          role='button'
          tabIndex={0}
          onClick={() => routerPush(link)}
          onKeyPress={() => routerPush(link)}
        >
          <S.Label>
            <S.LabelIcon bgColor={color}>
              <Image src={`/icons/${icon}.svg`} width='40' height='40' alt={title} />
            </S.LabelIcon>
            <Text weight='medium' margin='0'>
              {title}
            </Text>
          </S.Label>
        </S.LabelButton>
      )),
    [list, routerPush]
  )

  return (
    <S.Labels>
      <Row>
        <Col xs={12} lg={4}>
          <Text size='xxlg' family='heading' weight='bold' align={isLgBreakpoint ? 'left' : 'center'}>
            {title}
          </Text>
          <Text color='weak' margin='0 0 3rem' align={isLgBreakpoint ? 'left' : 'center'}>
            {subtitle}
          </Text>

          <S.ArrowButtons>
            <SliderArrow position='left' onClick={handlePrev} currentSlide={index} slideCount={6} slidesToShow={3} />
            <SliderArrow position='right' onClick={handleNext} currentSlide={index} slideCount={6} slidesToShow={3} />
          </S.ArrowButtons>
        </Col>
        <Col xs={12} lg={8}>
          {!isLgBreakpoint && <HorizontalScroll>{listLabels}</HorizontalScroll>}

          {isLgBreakpoint && (
            <Slider ref={sliderRef} {...settings}>
              {listLabels}
            </Slider>
          )}
        </Col>
      </Row>
    </S.Labels>
  )
}

export default Labels
