import { FC } from 'react'
import dynamic from 'next/dynamic'
import { Calculator } from '@/client/submodules/Calculator'
import Page from '@/client/submodules/Page'
import Hero from './Hero'
import Labels from './Labels'
import Learning from './Learning'
import About from './About'
import Advantages from './Advantages'
import CustomerStories from './CustomerStories'
import References from './References'
import * as S from './styles'
import { HomeProps } from './types'

export const Home: FC<HomeProps> = ({
  seo,
  header,
  footer,
  content: { hero, labels, learning, about, advantages, customer_stories, references }
}) => (
  <Page seo={seo} header={header} footer={footer}>
    <Hero hero={hero} />
    <S.WrapCalculator id='calculator'>
      <Calculator />
    </S.WrapCalculator>
    <Labels labels={labels} />
    <Learning learning={learning} />
    <About about={about} />
    <Advantages advantages={advantages} />
    <CustomerStories customer_stories={customer_stories} />
    <References references={references} />
  </Page>
)
