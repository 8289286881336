import Slider from 'react-slick'
import styled, { css } from 'styled-components'
import { theme, Col } from '@/client/components'
const { color, space, borders, breakpoint } = theme

export const CustomerStories = styled.div`
  padding-top: ${space.xl6};
  padding-bottom: ${space.xl2};
  background-color: ${color.grayscale.white};

  @media (min-width: ${breakpoint.lg}) {
    padding-bottom: 0;
  }
`

export const CustomerStoriesTop = styled(Col)`
  height: 5.5rem;
  padding: 0;

  @media (min-width: ${breakpoint.lg}) {
    display: flex;
    width: calc(100% - 1.5rem);
    height: calc(5.5rem - 1px);
    margin-left: ${space.sm};
    margin-right: ${space.sm};
    border-bottom: ${borders.sizes.sm} solid ${color.grayscale.gray};
  }
`

export const CustomerSlider = styled(Slider)`
  width: calc(100% - 7rem);
`

export const ArrowButtons = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 7rem;
  height: 100%;
  gap: ${space.md};

  button {
    position: static;
  }
`

export const CustomerWrapper = styled.div<{ active: boolean }>`
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  height: 5.5rem;
  padding: 0 ${space.lg};
  border: ${({ active }) =>
    active ? `${borders.sizes.md} solid ${color.primary.darker}` : `${borders.sizes.md} solid transparent`};
  border-radius: ${borders.radius.md};
  background-color: ${({ active }) => (active ? color.grayscale.light : 'trasparent')};
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    opacity: 1;
    filter: grayscale(0%);
    text-decoration: none;
  }

  @media (min-width: ${breakpoint.lg}) {
    padding: 0;
    border: none;
    background-color: transparent;
    opacity: ${({ active }) => (active ? '1' : '0.6')};
    filter: ${({ active }) => (active ? 'grayscale(0%)' : 'grayscale(100%)')};

    ${({ active }) =>
      active &&
      css`
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 3px;
          background-color: ${color.warning.pure};
        }
      `};
  }
`

export const Customer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const CustomerLogo = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  margin-right: ${space.sm};
  border-radius: ${borders.radius.circle};
  border: ${borders.sizes.md} solid ${color.grayscale.gray};
  padding: 2px;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

export const Story = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: ${space.xl};

  @media (min-width: ${breakpoint.lg}) {
    flex-direction: row;
    padding-top: ${space.xl5};
  }
`

export const StoryImg = styled.div`
  height: auto;
  width: 100%;
  max-width: 28rem;
  margin: 0 auto ${space.md};
  border-radius: 14px;

  @media (min-width: ${breakpoint.lg}) {
    position: relative;
    flex-direction: row;
    min-width: calc(50% - ${space.xl4});
    margin: 0 ${space.xl} ${space.md} ${space.lg};
    border: ${borders.sizes.md} dashed ${color.grayscale.gray};

    & > img {
      position: relative;
      top: -${space.xl};
      left: -${space.lg};
    }
  }

  @media (min-width: ${breakpoint.xl}) {
    margin-right: ${space.xl4};
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

export const StoryTestimonial = styled.div`
  padding-top: 3%;
`

export const StoryTestimonialBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${space.lg};

  button {
    min-width: 10rem;
    margin-top: ${space.md};

    @media (min-width: ${breakpoint.lg}) {
      margin-top: 0;
    }
  }

  @media (min-width: ${breakpoint.lg}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: ${space.xl3};
  }
`
