import { FC, useCallback, useRef, useState, MutableRefObject } from 'react'
import { default as SliderProps } from 'react-slick'
import { Row, Col, Text, HorizontalScroll, RatingStar, Icon, Button, Image } from '@/client/components'
import { useRouterPush, useWindowSize } from '@/client/hooks'
import { SliderArrow } from '@/client/submodules/Slider'
import * as S from './styles'
import { CustomerStoriesProps } from '../types'

const CustomerStories: FC<CustomerStoriesProps> = ({ customer_stories: { title, customers } }) => {
  const { routerPush } = useRouterPush()
  const sliderRef = useRef() as MutableRefObject<SliderProps>
  const [index, setIndex] = useState<number>(0)
  const [storyActive, setStoryActive] = useState<number>(0)
  const windowSize = useWindowSize()
  const isLgBreakpoint = windowSize?.width > 768
  const isXLBreakpoint = windowSize?.width > 992

  const handlePrev = useCallback(() => {
    sliderRef.current.slickPrev()
  }, [sliderRef])

  const handleNext = useCallback(() => {
    sliderRef.current.slickNext()
  }, [sliderRef])

  const beforeChange = useCallback((prev, next) => {
    setIndex(next)
  }, [])

  const settings = {
    infinite: false,
    arrows: false,
    speed: 500,
    beforeChange,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 99999,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <S.CustomerStories>
      <Row>
        <Col>
          <Text size='xxlg' weight='bold' align='center' margin='0 0 3rem'>
            {title}
          </Text>
        </Col>
      </Row>

      {!isLgBreakpoint && (
        <Row>
          <Col>
            <HorizontalScroll>
              {customers.map(({ logo, name, type }, index) => (
                <S.CustomerWrapper
                  role='button'
                  tabIndex={0}
                  key={name}
                  active={index === storyActive}
                  onClick={() => setStoryActive(index)}
                  onKeyPress={() => setStoryActive(index)}
                >
                  <S.Customer>
                    <S.CustomerLogo>
                      <Image src={logo.url} alt={logo.alt || name} width={logo.width} height={logo.height} />
                    </S.CustomerLogo>
                    <div>
                      <Text weight='medium' margin='0'>
                        {name}
                      </Text>
                      <Text color='weak' margin='0'>
                        {type}
                      </Text>
                    </div>
                  </S.Customer>
                </S.CustomerWrapper>
              ))}
            </HorizontalScroll>
          </Col>
        </Row>
      )}

      {isLgBreakpoint && (
        <Row>
          <S.CustomerStoriesTop>
            <S.CustomerSlider ref={sliderRef} {...settings}>
              {customers.map(({ logo, name, type }, index) => (
                <S.CustomerWrapper
                  role='button'
                  tabIndex={0}
                  key={name}
                  active={index === storyActive}
                  onClick={() => setStoryActive(index)}
                  onKeyPress={() => setStoryActive(index)}
                >
                  <S.Customer>
                    <S.CustomerLogo>
                      <Image src={logo.url} alt={logo.alt || name} width={logo.width} height={logo.height} />
                    </S.CustomerLogo>
                    <div>
                      <Text size='sm' weight='bold' margin='0'>
                        {name}
                      </Text>
                      <Text color='weak' size='xs' margin='0'>
                        {type}
                      </Text>
                    </div>
                  </S.Customer>
                </S.CustomerWrapper>
              ))}
            </S.CustomerSlider>

            <S.ArrowButtons>
              <SliderArrow
                position='left'
                onClick={handlePrev}
                currentSlide={index}
                slideCount={customers.length}
                slidesToShow={isXLBreakpoint ? 4 : 3}
              />
              <SliderArrow
                position='right'
                onClick={handleNext}
                currentSlide={index}
                slideCount={customers.length}
                slidesToShow={isXLBreakpoint ? 4 : 3}
              />
            </S.ArrowButtons>
          </S.CustomerStoriesTop>
        </Row>
      )}
      <Row>
        <Col>
          <S.Story>
            <S.StoryImg>
              <Image
                src={customers[storyActive].testimonial_img.url}
                alt={customers[storyActive].testimonial_img.alt || customers[storyActive].witness}
                width={customers[storyActive].testimonial_img.width}
                height={customers[storyActive].testimonial_img.height}
              />
            </S.StoryImg>
            <S.StoryTestimonial>
              <RatingStar stars={5} filled={Number(customers[storyActive].rating)} />
              <Text italic margin='1.5rem 0 0'>
                {customers[storyActive].testimonial}
              </Text>
              <S.StoryTestimonialBottom>
                <div>
                  <Text color='primary-dark' family='heading' weight='bold' margin='0 0 0.5rem'>
                    {customers[storyActive].witness}
                  </Text>
                  <Text color='weak' size='sm' margin='0'>
                    {customers[storyActive].witness_position}
                  </Text>
                </div>
                <Button kind='weak' size='small' isOutlined onClick={() => routerPush(customers[storyActive].link)}>
                  {customers[storyActive].button_label}
                  <Icon name='arrow-right' />
                </Button>
              </S.StoryTestimonialBottom>
            </S.StoryTestimonial>
          </S.Story>
        </Col>
      </Row>
    </S.CustomerStories>
  )
}

export default CustomerStories
