import { FC } from 'react'
import { Row, Col, CardBlog, HorizontalScroll } from '@/client/components'
import { useWindowSize } from '@/client/hooks'
import { SliderArrow } from '@/client/submodules/Slider'
import * as S from './styles'
import { LearningProps } from '../types'

const settings = {
  speed: 500,
  infinite: false,
  prevArrow: <SliderArrow position='left' slidesToShow={3} />,
  nextArrow: <SliderArrow position='right' slidesToShow={3} />,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 99999,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    }
  ]
}

const Learning: FC<LearningProps> = ({ learning }) => {
  const windowSize = useWindowSize()
  const isLgBreakpoint = windowSize?.width > 768

  return (
    <S.Learning>
      <Row>
        <Col xs={12}>
          <S.LearningInner>
            {!isLgBreakpoint && (
              <HorizontalScroll>
                {learning.map(({ link, image, title, description, button_label }) => (
                  <S.CardBlogWrapper key={title}>
                    <CardBlog
                      href={link}
                      image={image}
                      title={title}
                      description={description}
                      buttonLabel={button_label}
                    />
                  </S.CardBlogWrapper>
                ))}
              </HorizontalScroll>
            )}
            {isLgBreakpoint && (
              <S.Slide {...settings}>
                {learning.map(({ link, image, title, description, button_label }) => (
                  <S.CardBlogWrapper key={title}>
                    <CardBlog
                      href={link}
                      image={image}
                      title={title}
                      description={description}
                      buttonLabel={button_label}
                    />
                  </S.CardBlogWrapper>
                ))}
              </S.Slide>
            )}
          </S.LearningInner>
        </Col>
      </Row>
    </S.Learning>
  )
}

export default Learning
